.leaflet-container {
  width: 100%;
  height: 100%;
}
.leaflet-pane {
  width: 100%;
  height: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: 0;
}